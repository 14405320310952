<template>
    <div class="new-lead">
        <div class="new-lead__container">
            <h1 class="new-lead__title">Отправить заявку на работы в Московской области</h1>
            <p class="new-lead__subtitle">Свяжитесь с нами моментально</p>
            <p class="new-lead__description">Отвечаем ежедневно с 10 до 19</p>
            <div class="new-lead__card">
                <!--                <a href="#" class="form-element">-->
                <!--                    <img :src="require('@/assets/images/telegram.svg')" alt="telegram">-->
                <!--                    <span>Telegram (написать)</span>-->
                <!--                </a>-->
                <a href="tel:+74995043272" class="form-element">
                    <img :src="require('@/assets/images/phone.svg')" alt="phone">
                    <span>Телефон (позвонить)</span>
                </a>
            </div>
            <p class="new-lead__subtitle">Оставьте заявку на обратный звонок</p>
            <p class="new-lead__description">Принимаем круглосуточно</p>
            <div class="new-lead__card">
                <p>Ваш телефон *</p>
<!--                <phone-mask-input-->
<!--                    v-model="phone"-->
<!--                    inputClass="form-element"-->
<!--                    placeholder="+7 999 999-99-99"-->
<!--                />-->
                <input class="form-element" v-model="phone" type="text" ref="input" v-mask="'+7 (___) ___-__-__'">
                <img :src="require('@/assets/images/phone2.svg')" alt="phone2">
                <button class="form-element top-32" @click="createLead">
                    Отправить
                </button>
                <p class="person-data">Нажимая на кнопку, вы соглашаетесь на обработку персональных данных</p>
            </div>
            <footer>
                Заявка будет обработана официальным партнером компании “Зеленый вектор”
            </footer>
        </div>
        <img class="top-right-bg-image" :src="require('@/assets/images/blue_stroke_1.svg')" alt="blue_stroke_1">
        <img class="bottom-left-bg-image" :src="require('@/assets/images/Vector.svg')" alt="Vector">

        <div class="modal-success-new-lead" v-if="showModal">
            <div>
                <h2>{{ message }}</h2>
                <button class="form-element top-32" @click="showModal = false">
                    Закрыть
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import PhoneMaskInput from "vue-phone-mask-input";
import OtherService from "@/services/other.service";
import Vue from "vue";

Vue.directive('mask', {
    inserted: function (el, binding) {
        let mask = binding.value,
            first = mask.indexOf('_'),
            fieldsL = mask.replace(/[^_]/gm, '').length,
            clean = mask.replace(/[^0-9_]/gm, ''),
            indexes = []

        for (let i = 0; i < clean.length; i++) {
            if (!isNaN(clean[i])) {
                indexes.push(i)
            }
        }

        el.value = mask
        el.clean = mask.replace(/[^0-9]/gm, '')

        function maskIt(event, start) {
            let value = el.value,
                filtred = value.replace(/[^0-9]/gm, ''),
                result = ''

            if (value.length < first) {
                value = mask + value
                filtred = value.replace(/[^0-9]/gm, '')
            }

            for (let i = 0; i < filtred.length; i++) {
                if (indexes.indexOf(i) === -1) {
                    result += filtred[i]
                }
            }

            value = ''
            let cursor = 0

            for (let i = 0; i < mask.length; i++) {
                if (mask[i] === '_' && result) {
                    value += result[0]
                    result = result.slice(1)
                    cursor = i + 1

                } else {
                    value += mask[i]
                }
            }

            if (cursor < first) {
                cursor = first
            }

            el.value = value

            el.clean = el.value.replace(/[^0-9]/gm, '')

            el.setSelectionRange(cursor, cursor)
        }

        el.addEventListener('focus', function (event) {
            event.preventDefault()
        })

        el.addEventListener('click', function (event) {
            event.preventDefault()
            let start = el.value.indexOf('_')

            if (start === -1) {
                start = el.value.length
            }

            el.setSelectionRange(start, start)

        })

        el.addEventListener('paste', function (event) {
            let start = el.selectionStart

            if (start < first) {
                el.value = '_' + el.value
            }
        })

        el.addEventListener('input', function (event) {
            let start = el.selectionStart
            maskIt(event, start)
        })

    }
})

export default {
    components: [
        PhoneMaskInput,
    ],
    data() {
        return {
            showModal: false,
            phone: '',
            message: '',
        }
    },
    methods: {
        createLead() {
            const phoneNumber = this.phone.replace('_', '').substring(0, 18)
            OtherService.createAmoCrmLead({
                client_secret: process.env.VUE_APP_CLIENT_SECRET,
                phone_number: phoneNumber,
                tags: 'Кабинет партнера, Зеленый вектор',
            })
                .then(r => {
                    this.showModal = true
                    this.message = r.data.message
                })
        },
    }
}
</script>

<style lang="scss">
.new-lead {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    background: #fff;
    color: #26201D;
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
        max-width: 780px;
        text-align: center;
        z-index: 1;
        padding: 20px 0 20px 0;
    }

    &__title {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
    }

    &__subtitle {
        margin-top: 12px;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
    }

    &__description {
        margin-top: 4px;
        font-size: 16px;
        line-height: 20px;
    }

    &__card {
        margin-top: 20px;
        background: #FCFCFC;
        border: 1px solid rgba(38, 32, 29, 0.1);
        border-radius: 8px;
        padding: 20px 100px;
        position: relative;
        text-align: left;

        > p {
            margin-bottom: 8px;
        }

        > img {
            position: absolute;
            top: 62px;
            left: 116px;
        }
    }

    .form-element {
        display: flex;
        align-items: center;
        width: 100%;
        border: 0;
        background: #FFFFFF;
        box-shadow: 0 4px 14px rgba(38, 32, 29, 0.05);
        border-radius: 8px;
        padding: 10px 15px;

        img {
            margin-right: 18px;
        }
    }

    a.form-element {
        cursor: pointer;
        color: #26201D;

        &:hover {
            background: #fcfcfc;
        }
    }

    .top-32 {
        margin-top: 15px;
    }

    button.form-element {
        background: linear-gradient(270deg, #039BE5 0%, #4FC3F7 100%);
        cursor: pointer;
        justify-content: center;
        color: #fff;
        text-transform: uppercase;

        &:hover {
            background: linear-gradient(270deg, #0390d5 0%, #47b2e1 100%);
        }
    }

    input.form-element {
        padding-left: 62px;
    }

    .person-data {
        margin-top: 12px;
        font-size: 14px;
        line-height: 26px;
    }

    footer {
        margin-top: 30px;
    }

    .top-right-bg-image {
        position: fixed;
        top: 0;
        right: 0;
    }

    .bottom-left-bg-image {
        position: fixed;
        bottom: 0;
        left: 0;
    }
}

.modal-success-new-lead {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.20);
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
        padding: 20px;
        border-radius: 8px;
        background: #fff;

        h2 {
            margin-bottom: 30px;
        }
    }
}

.phone-mask-wrapper-lib {
    height: unset !important;
}

@media screen and (max-width: 1024px) {
    .new-lead {
        flex-direction: unset !important;
        padding: 20px;

        &__title {
            font-size: 30px;
            line-height: 36px;
        }

        &__subtitle {
            margin-top: 40px;
            font-size: 26px;
            line-height: 30px;
        }

        &__description {
            margin-top: 8px;
            font-size: 19px;
            line-height: 24px;
        }

        &__card {
            padding: 30px 25px;

            > p {
                font-size: 14px;
            }

            > img {
                top: 73px;
                left: 45px;
            }
        }

        .top-right-bg-image {
            height: 150px;
        }

        .bottom-left-bg-image {
            height: 150px;
        }
    }
}

@media screen and (min-height: 740px) {
    .new-lead {
        &__title {
            font-size: 32px;
            line-height: 36px;
        }

        &__subtitle {
            font-size: 26px;
            line-height: 30px;
        }

        &__description {
            margin-top: 8px;
            font-size: 19px;
            line-height: 24px;
        }

        &__card {
            //padding: 30px 25px;

            > p {
                //font-size: 14px;
            }

            > img {
                //top: 85px;
                //left: 45px;
            }
        }

        //
        //.top-right-bg-image {
        //    height: 120px;
        //}
        //
        //.bottom-left-bg-image {
        //    height: 120px;
        //}
    }
}

@media screen and (min-height: 840px) {
    .new-lead {
        &__title {
            font-size: 30px;
            line-height: 36px;
        }

        &__subtitle {
            margin-top: 40px;
            font-size: 26px;
            line-height: 30px;
        }

        &__description {
            margin-top: 8px;
            font-size: 19px;
            line-height: 24px;
        }

        &__card {
            padding: 30px 25px;

            > p {
                font-size: 14px;
            }

            > img {
                top: 73px;
                left: 45px;
            }
        }

        //
        //.top-right-bg-image {
        //    height: 200px;
        //}
        //
        //.bottom-left-bg-image {
        //    height: 200px;
        //}
    }
}
</style>
